h3, p, a {
  color: #fff;
  text-align: left;
}

a {
  font-style: italic;
}

.templateName {
  margin-right: 10px;
}