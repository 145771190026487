.modal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  .content {
    text-align: right;
    width: 700px;
    margin: 0 auto;

    .close {
      cursor: pointer;
    }

    textarea {
      height: 600px;
      width: 100%;
    }
  }
}