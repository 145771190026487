.formRenderer {
  .formInput {
    height: 37px;
    width: 100%;
    padding: 10px;
    background: rgba(0, 0, 0, 0.3);
    box-shadow: rgba(100, 100, 100, 0.2) 0 -5px 45px inset, rgba(255, 255, 255, 0.2) 0 1px 1px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.3);
    border-image: initial;
    border-radius: 4px;
    font: 400 13px Arial;
    resize: vertical;
    color: rgb(255, 255, 255);
  }

  .selectCustomClassWrapper {
    .select__control {
      background-color: rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 -5px 45px rgba(100, 100, 100, .2), 0 1px 1px rgba(255, 255, 255, .2);
      text-align: left;
      font: 400 13px Arial;
      border-color: rgba(0, 0, 0, 0.3);
      margin: 3px 0;
    }

    .select__single-value {
      color: #fff;
    }
  }

  .expanderDiv {
    text-align: right;

    span {
      cursor: pointer;
    }
  }

  .separator {
    margin: 20px 0;

    span {
      padding: 0 20px;
    }
  }
}