$mainColor: #4a77d4;

.switchWrapper {
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-bottom: 1em;

  input[type="checkbox"] {
    &:checked,
    &:not(:checked){
      left: auto;
      visibility: visible;
    }

    &.toggle-switch {
      appearance: none;
      outline: none;
      position: relative;
      cursor: pointer;
      width: 3em;
      height: 1.5em;
      border-radius: calc(1.5em / 2);
      background: rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 0 calc(1.5em / 4) rgba(0, 0, 0, 0.75);

      &:focus {
        box-shadow: 0 0 0 #df2, inset 0 0 calc(1.5em / 4) $mainColor;
      }

      &:checked {
        background: rgba(0, 0, 0, 0.6);

        &::after {
          left: calc(3em - 1.5em + 0.2em);
          background: $mainColor;
        }
      }

      &:hover {
        background: rgba(0, 0, 0, 0.4);
      }

      &::after {
        content: '';
        position: absolute;
        top: 0.2em;;
        left: 0.2em;;
        bottom: 0.2em;;
        width: calc(1.5em - (0.2em * 2));
        border-radius: 50%;
        background: #eee;
        box-shadow: inset 0 0 calc(1.5em / 4) rgba(255, 255, 255, 0.75), 0 0 0.2em 0 rgba(0, 0, 0, 0.75);
        transition: left 200ms cubic-bezier(0.76, 0.08, 0.54, 1.4), background 200ms cubic-bezier(0.76, 0.08, 0.54, 1.4);
      }

      + label {
        cursor: pointer;
        position: relative;
        top: -4px;
      }
    }
  }
}
