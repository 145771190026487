.login {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -150px 0 0 -150px;
  width:300px;
  height:300px;

  h1 {
    color: #fff;
    text-shadow: 0 0 10px rgba(0,0,0,0.3);
    letter-spacing:1px;
    text-align:center;
  }

  .confirmation {
    opacity: 0;
    height: 0;
    display: none;
    position: relative;
    transition: height .25s ease-in, opacity .25s ease-in;
  }

  .shown {
    display: inline-block;
    opacity: 1;
    height: auto;
  }
}