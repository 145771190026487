.methodPicker {
  width: 500px;
  display: block;
  margin: 50px auto 0;
  text-align: center;

  span {
    input{
      display: inline-block;
      width: auto;
    }
  }

  [type="radio"]:checked, [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
    visibility: hidden;
  }

  .checkbox-tools:checked + label, .checkbox-tools:not(:checked) + label {
    position: relative;
    display: inline-block;
    padding: 20px;
    width: 110px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    margin: 0 5px 10px;
    text-align: center;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    text-transform: uppercase;
    color: #fff;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
  }

  .checkbox-tools:not(:checked) + label {
    background-color: transparent;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }

  .checkbox-tools:checked + label {
    background-color: #4a77d4;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  .checkbox-tools:not(:checked) + label:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  .checkbox-tools:checked + label::before, .checkbox-tools:not(:checked) + label::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: #ccc;
    z-index: -1;
  }
}
